import { useState, useEffect } from 'react';
import useSelectionsData from "../../../state/selections/UseSelectionsData";
import useSelectionsEntriesExportData from '../../../state/selectionsEntriesExport/UseSelectionsEntriesExportData';
import useSelectionsTimeStandardData from '../../../state/selectionsTimeStandard/UseSelectionsTimeStandardData';
import useSelectionsExportParametersData from '../../../state/selectionsEntriesExport/UseSelectionsExportParametersData';

import hyTekExport from '../../meet/meetHost/lib/HyTekExport';

const useSelectionsExport = () => {
  const { selectionsState } = useSelectionsData();
  const { selectionsEntriesExportState, getSelectionsEntriesExport } = useSelectionsEntriesExportData();
  const { selectionsTimeStandardState, setSelectionsTimeStandardState, getSelectionsTimeStandards } = useSelectionsTimeStandardData();
  const { selectionsExportParametersState, getSelectionsExportParameters } = useSelectionsExportParametersData();

  const [uiState, setUiState] = useState({ exportFileCreated: false, fileContent: "" });

  let fileString = "";

  const onExportSubmitFormCallback = (formState) => {
    //keeping this commented out code here for now so that we can compare during testing if necessary

    //let standardId = -1;
    // let includeAllIndEventEntries = formState.includeAllIndEventEntries === true ? 1 : 0;

    let includeAllIndEventEntries = parseInt(selectionsExportParametersState.arrayData[0].ParameterValue);
    let relayAthsIncludeAllIndEventEntries = parseInt(selectionsExportParametersState.arrayData[1].ParameterValue);
    let standardId = parseInt(selectionsExportParametersState.arrayData[2].ParameterValue);

    let numberIndividualEventSwimmersSelected = -1
    // if (formState.relayAthsIncludeAllIndEventEntries === false) {
    if (relayAthsIncludeAllIndEventEntries === 0) {
      numberIndividualEventSwimmersSelected = 4;
      //standardId = formState.timeStandardId;
    }

    getSelectionsEntriesExport(
      selectionsState.objData.selectionMeetId, 
      includeAllIndEventEntries, 
      0, 
      standardId, 
      false, 
      numberIndividualEventSwimmersSelected);

    // if (selectionsState.objData.meetHost === 'NCAA Div I' || selectionsState.objData.meetHost === 'NCAA Div II') {
    //   getSelectionsEntriesExport(
    //     selectionsState.objData.selectionMeetId,
    //     1,
    //     0,
    //     selectionsTimeStandardState.items[0].id,
    //     false,
    //     4);
    // } else {
    //   getSelectionsEntriesExport(
    //     selectionsState.objData.selectionMeetId,
    //     1,
    //     0,
    //     -1,
    //     false,
    //     -1);
    // }
  }

  function createHyTekEntriesExportFile() {
    const teams = selectionsEntriesExportState.objData.teams === undefined ? [] : selectionsEntriesExportState.objData.teams;
    fileString += hyTekExport.createTeams(teams, true);

    // const unattached = omeEntriesExportState.objData.unattachedSwimmers === undefined ? [] : omeEntriesExportState.objData.unattachedSwimmers;
    // fileString += hyTekExport.createUnattachedSwimmers(unattached);

    fileString = hyTekExport.buildCreatedByRec(selectionsState.objData, fileString, true) + fileString;

  }

  function provideFile() {
    const file = new Blob([uiState.fileContent], { type: 'application/text' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);

    let meetName = selectionsState.objData.meetName.replace("&", "").replace("'", "").replace("\\", "").replace("/", "").replace(".", "").replace("#", "");
    const selectionMeetId = selectionsState.objData.selectionMeetId;

    let fileName = `${selectionMeetId}_${meetName}_export.txt`;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
  }

  useEffect(() => {
    if (selectionsState.isObjLoaded === true) {
      const getSelectionsTimeStandardsPromise = getSelectionsTimeStandards(selectionsState.objData.orgUnitId, selectionsState.objData.omeMeetId);
      if (getSelectionsTimeStandardsPromise !== null) {
        getSelectionsTimeStandardsPromise.then((newState) => {
          if (newState !== null) {
            setSelectionsTimeStandardState({
              ...newState,
              items: newState.arrayData.map(x => { return { id: x.timeStandardId, name: x.standardName }; }),
              areItemsLoaded: true
            });
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsState]);

  useEffect(() => {
    if (selectionsEntriesExportState.isObjLoaded === true) {
      createHyTekEntriesExportFile();
      setUiState({ ...uiState, exportFileCreated: true, fileContent: fileString });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsEntriesExportState]);

  useEffect(() => {
    if (uiState.exportFileCreated === true && uiState.fileContent.length > 0) {
      provideFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState]);

  useEffect(() => {
    if (selectionsState.isObjLoaded === true) {
      const getSelectionsExportParametersPromise = getSelectionsExportParameters(selectionsState.objData.orgUnitId);
      // const getSelectionsExportParametersPromise = getSelectionsExportParameters(7969);

      if (getSelectionsExportParametersPromise !== null) {
        getSelectionsExportParametersPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    selectionsState,
    selectionsEntriesExportState,
    selectionsTimeStandardState,
    selectionsExportParametersState,
    onExportSubmitFormCallback
  };
};

export default useSelectionsExport;