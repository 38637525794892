import React, { Fragment } from 'react';

import useAddMeetTimeIndividual from './UseAddMeetTimeIndividual';

import AddMeetTimeEventCombobox from '../../../../components/comboboxes/AddMeetTimeEventCombobox';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SearchMemberWithLscPopup from '../../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';
import LscDropdown from '../../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatTimeForInput } from '../../../../../common/utils/TimesUtils';
import Constants from '../../../../../common/utils/Constants';


const AddMeetTimeIndividual = () => {
  const {
    formState,
    errorState,
    eventState,
    meetDatesState,
    meetTimeAddPostDataState,
    sessionTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    setFormData,
    lscState,
    meetState
  } = useAddMeetTimeIndividual();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Individual Meet Time Result</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {/* //TODO: get 'USAS' from env var */}
            <SearchMemberWithLscPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              orgUnitId={meetState.objData.hostOrgUnitId}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged}
              isForUsas={
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "USAS" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "LSC" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "CLUB"} />
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <AddMeetTimeEventCombobox
              label="Event*"
              name="eventId"
              valueToMatch={formState.eventName}
              error={errorState.eventId}
              message={errorState.eventId}
              timesData={eventState.arrayData}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(
                  newValue, 'eventId',
                  newValueLabel, 'eventName', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Time*"
              name="time"
              error={errorState.time}
              message={errorState.time}
              value={formatTimeForInput(formState.time)}
              onChange={(value) => { onFormValueChanged('time', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Session"
              name="sessionId"
              options={sessionTypeState}
              value={formState.sessionId}
              error={errorState.sessionId}
              message={errorState.sessionId}
              isloading={false}
              onChange={(value) => { onFormValueChanged('sessionId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Swim Date*"
              name="swimDateId"
              options={meetDatesState}
              value={formState.swimDateId}
              error={errorState.swimDateId}
              message={errorState.swimDateId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(
                  newValue, 'swimDateId',
                  newValueLabel, 'swimDate', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Place"
              name="finishPosition"
              type="number"
              min="1"
              error={errorState.finishPosition}
              message={errorState.finishPosition}
              value={formState.finishPosition}
              onChange={(value) => { onFormValueChanged('finishPosition', (value)) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Is Relay Leadoff?"
              name="isLeadOffTime"
              checked={formState.isLeadOffTime === true}
              error={errorState.isLeadOffTime}
              message={errorState.isLeadOffTime}
              onChange={(value) => { onFormValueChanged('isLeadOffTime', value) }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <LscDropdown
              label="LSC*"
              name="lsc"
              value={formState.lscId}
              error={errorState.lscId}
              message={errorState.lscId}
              onChange={(value) => { onFormValueChanged('lscId', value) }} />&nbsp;
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <Input
              label="Club Code*"
              name="clubCode"
              value={formState.clubCode}
              error={errorState.clubCode}
              message={errorState.clubCode}
              onChange={(value) => { onFormValueChanged('clubCode', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetTimeAddPostDataState.isSaving} />
    </Fragment >
  )
}

export default AddMeetTimeIndividual;