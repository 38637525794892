import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postMeetResultDocFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/MeetFiles/ResultsDocument`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_MEET_DOCS_MEET_RESULT_DOCS_BASE_UPLOAD_URL;

const INITIAL_MEET_RESULT_DOC_FILE_UPLOAD_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetResultDocFileData = {
  BASE_UPLOAD_URL,
  INITIAL_MEET_RESULT_DOC_FILE_UPLOAD_STATE,
  postMeetResultDocFileData
};

export default MeetResultDocFileData;