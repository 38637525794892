import { Fragment } from "react";

import useParticipantSelectionsReadOnlyForm from "./UseParticipantSelectionsReadOnlyForm";

import Headings from "../../../../../common/components/headings/Headings";

const ParticipantSelectionsReadOnlyForm = ({ selectionsStateObj, selectionsParticipantsStateObj }) => {
  const {
    state
  } = useParticipantSelectionsReadOnlyForm(selectionsStateObj, selectionsParticipantsStateObj);

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Participant Caps</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          Swimmers: {state.maxSwimmers}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          Divers: {state.maxDivers}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          Total: {state.maxParticipants}
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className="col-xs-12 usas-extra-top-margin">
          <Headings.H5>Total Number of Competitors Selected</Headings.H5>
        </div>
        <div className="col-xs-12 col-sm-6">
          Selected Swimmers: {state.selectedSwimmers}
        </div>
        <div className="col-xs-12 col-sm-6">
          Selected Divers: {state.selectedDivers}
        </div>
        <div className="col-xs-12 col-sm-6">
          Total Selected: {state.totalSelected}
        </div>
        <div className="col-xs-12 col-sm-6">
          Total Selected Swimmers and Divers: {state.totalSelectedSwimmersAndDivers}
        </div>
        <div className="col-xs-12 col-sm-6">
          Relay Rows to Place/Seed: {state.actualRelayRowsToSeed}
        </div>
        <div className="col-xs-12 col-sm-6">
          Minimum Number of Relays to Accept: {state.minRelayRowsToAccept}
        </div>
        <div className="col-xs-12 col-sm-6">
          Relay Swimmers Selected Based on Relay Only Relay Assignment: {state.relaySelectedBasedOnRelayOnlyRelayAssignment}
        </div>
        <div className="col-xs-12 col-sm-6">
          Relay Swimmers Selected Based on Individual Entry Relay Assignment: {state.relaySelectedBasedOnIndEntryRelayAssignment}
        </div>
        <div className="col-xs-12 col-sm-6">
          Relay Swimmers Selected Based on Relay Only Roster Designation: {state.relaySelectedBasedOnRelayOnlyRosterDesignation}  
        </div>
      </div>
    </Fragment>
  );
};

export default ParticipantSelectionsReadOnlyForm;