import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import style from './Table.module.css';

const GridRow = ({ invalidTime, onEditTime, onDeleteTime }) => {
  return (
    <Fragment>
      <tr style={{ fontSize: 14 }}>
        <td>{invalidTime.name}</td>
        <td>{invalidTime.memberId}</td>
        <td>{invalidTime.eventCode}</td>
        <td style={{ paddingLeft: 30 }}>{invalidTime.swimTime}</td>
        <td style={{ paddingLeft: 10 }}>{invalidTime.firstNameFromFile}</td>
        <td style={{ paddingLeft: 15 }}>{invalidTime.lastNameFromFile}</td>
        <td style={{ paddingLeft: 0 }}>{formatDate(invalidTime.birthDateFromFile) === Constants.DEFAULT_MIN_DATE ? '' : formatDate(invalidTime.birthDateFromFile)}</td>
        <td>{invalidTime.lscCodeFromFile}/{invalidTime.clubCodeFromFile}</td>
        <td style={{ textAlign: 'center' }}>{invalidTime.assignedLscCode}/{invalidTime.assignedClubCode}</td>
        <td>
          {onEditTime &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onEditTime(e, invalidTime)}>
              <EditIcon />
            </button>}
          <button className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteTime(e, invalidTime.swimTimeRecognitionId)}>
            <DeleteIcon toolTipText="Delete all time usage" />
          </button>
        </td>
      </tr>
      <tr>
        <td colSpan="11" style={{ color: 'darkblue', background: 'lightgray', fontWeight: 'normal', borderTopWidth: 0 }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{invalidTime.validationTypeName}
        </td>
      </tr>
    </Fragment>
  );
};

const TimesValidationLargeGrid = ({ gridData, isLoading, onEditTime, onDeleteTime }) => (
  <Fragment>
    <table className={[global.UsasTable, style.tableFixHead, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr style={{ fontSize: 13 }}>
          <th style={{ paddingLeft: 14 }}>Name</th>
          <th>Member ID</th>
          <th style={{ textAlign: 'center' }}>Event</th>
          <th>Swim Time</th>
          <th style={{ paddingLeft: 0 }}>First Name (file)</th>
          <th style={{ paddingLeft: 0 }}>Last Name (file)</th>
          <th>Birth Date (file)</th>
          <th>LSC/Club (file)</th>
          <th>Assigned LSC/Club</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="11">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((invalidTime) =>
              <GridRow
                key={invalidTime.swimTimeRecognitionId}
                invalidTime={invalidTime}
                onEditTime={onEditTime}
                onDeleteTime={onDeleteTime} />)
            :
            <tr>
              <td colSpan={11}>No Invalid Times</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default TimesValidationLargeGrid;