import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

import useMeetData from '../../../../common/state/meet/UseMeetData';

import NavLinks from '../NavLinks';

import useTimeValidationData from '../../../state/timeValidation/UseTimeValidationData';

const useTimesValidation = () => {
  const navigate = useNavigate();
  const { meetState } = useMeetData();
  const { timeValidationState, getTimeValidations, putTimeValidations, deleteInvalidTime } = useTimeValidationData();
  const [ modalState, setModalState ] = useState(getInitialModalState());

  const onEditTime = (e, invalidTime) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_VALIDATION_DETAIL, {
      state: {
        invalidTime: invalidTime
      }
    });
  }

  const onDeleteTime = (e, swimTimeRecognitionId) => {
    const deleteInvalidTimePromise = deleteInvalidTime(modalState.swimTimeRecognitionId, meetState.objData.meetId);

    if (deleteInvalidTimePromise !== null) {
      deleteInvalidTimePromise.then((newState) => {
        if (newState !== null) {
          // Do something with the newState
          setModalState(getInitialModalState());
        }
      }).catch((e) => {
        setContextStateError(true);
      });
    }
  }

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onOpenModalClicked = (e, swimTimeRecognitionId) => {
    const invalidTimeObj = timeValidationState.arrayData.find(x => x.swimTimeRecognitionId === swimTimeRecognitionId);

    setModalState({
      ...modalState,
      swimTimeRecognitionId: swimTimeRecognitionId,
      swimmerName: invalidTimeObj.name,
      swimTime: formatTimeForDisplay(invalidTimeObj.swimTime),
      displayPopUp: true
    });
  };

  const onProcessValidations = () => {
    const putTimeValidationsPromise = putTimeValidations(meetState.objData.meetId);

      if (putTimeValidationsPromise !== null) {
        putTimeValidationsPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
  }

  useEffect(() => {
      const getTimeValidationsPromise = getTimeValidations(meetState.objData.meetId);

      if (getTimeValidationsPromise !== null) {
        getTimeValidationsPromise.then((newState) => {
          if (newState !== null) {
            // Do something with the newState
          }
        }).catch((e) => {
          setContextStateError(true);
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialModalState() {
    return {
      displayPopUp: false,
      currentTimeInfo: '',
      modalTitle: 'Delete all time usage?'
    };
  };

  return { 
    meetState, 
    timeValidationState, 
    modalState,
    onEditTime, 
    onProcessValidations,
    onDeleteTime,
    onModalCanceled,
    onOpenModalClicked
  };
}

export default useTimesValidation;