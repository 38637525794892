import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getSelectionsExportParametersData = (orgUnitId, state, setState) => {
  if (orgUnitId.length > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);

    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const url = `/OmeSelectionsMeet/ExportParameters/${orgUnitIdForUrl}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const SelectionsExportParametersData = {
  getSelectionsExportParametersData
};

export default SelectionsExportParametersData;