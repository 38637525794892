import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddMeetTimeRelayValidation';

import { getMeetDates } from '../utils/MeetUtils';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useEventData from '../../../../../common/state/event/UseEventData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

import NavLinks from '../../NavLinks';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import useLscData from '../../../../../common/state/lsc/UseLscData';

const useAddMeetTimeRelay = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const { meetState } = useMeetData();
  const { lscState } = useLscData();
  const { eventState, getEvents } = useEventData();
  const { meetTimeAddPostDataState, setMeetTimeAddPostDataState, postMeetTime, meetTimesAddState } = useMeetTimesData();
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const [meetDatesState, setMeetDatesState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Swim Date" }
  ]);

  const [eventCompetitionGenderTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select an Event Gender" },
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
    { id: 3, name: "Mixed" }
  ]);

  const [sessionTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Session Type" },
    { id: 1, name: "Prelim" },
    { id: 2, name: "SwimOff" },
    { id: 3, name: "Final" },
    { id: 4, name: "SemiFinal" },
    { id: 5, name: "QuarterFinal" },
    { id: 6, name: "TimedFinal" }
  ]);

  const RelayLegEventIdMap = {
    23: { leg1EventId: 1, leg2EventId: 1, leg3EventId: 1, leg4EventId: 1 },
    24: { leg1EventId: 2, leg2EventId: 2, leg3EventId: 2, leg4EventId: 2 },
    25: { leg1EventId: 3, leg2EventId: 3, leg3EventId: 3, leg4EventId: 3 },
    26: { leg1EventId: 11, leg2EventId: 14, leg3EventId: 17, leg4EventId: 1 },
    27: { leg1EventId: 12, leg2EventId: 15, leg3EventId: 18, leg4EventId: 2 },

    50: { leg1EventId: 28, leg2EventId: 28, leg3EventId: 28, leg4EventId: 28 },
    51: { leg1EventId: 29, leg2EventId: 29, leg3EventId: 29, leg4EventId: 29 },
    52: { leg1EventId: 30, leg2EventId: 30, leg3EventId: 30, leg4EventId: 30 },
    53: { leg1EventId: 38, leg2EventId: 41, leg3EventId: 44, leg4EventId: 28 },
    54: { leg1EventId: 39, leg2EventId: 42, leg3EventId: 45, leg4EventId: 29 },

    76: { leg1EventId: 55, leg2EventId: 55, leg3EventId: 55, leg4EventId: 55 },
    77: { leg1EventId: 56, leg2EventId: 56, leg3EventId: 56, leg4EventId: 56 },
    78: { leg1EventId: 57, leg2EventId: 57, leg3EventId: 57, leg4EventId: 57 },
    79: { leg1EventId: 65, leg2EventId: 68, leg3EventId: 71, leg4EventId: 55 },
    80: { leg1EventId: 66, leg2EventId: 69, leg3EventId: 72, leg4EventId: 56 }
  }

  function submitFormCallback(formState) {
    postMeetTime(createMeetTimeRelayObject(formState));
    
    setState({
      ...state,
      tryPageRedirect: true
    });
  }

  const createMeetTimeRelayObject = (formState) => {
    return {
      personId: null,
      eventId: formState.eventId,
      meetId: formState.meetId,
      eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
      sessionId: formState.sessionId,
      time: formatTimeForFilterObject(formState.time),
      swimDate: formState.swimDate,
      finishPosition: parseInt(formState.finishPosition.trim()),
      isLeadOffTime: null,
      teamName: formState.teamName.trim(),
      lscCode: lscState.data.find(x => x.orgUnitId === formState.lscId).orgUnitCode,
      clubCode: formState.clubCode,
      swimTimeRelay: [
        {
          personId: formState.personId1,
          legNumber: 1,
          legEventId: RelayLegEventIdMap[formState.eventId].leg1EventId,
          legTime: formatTimeForFilterObject(formState.time1)
        },
        {
          personId: formState.personId2,
          legNumber: 2,
          legEventId: RelayLegEventIdMap[formState.eventId].leg2EventId,
          legTime: formatTimeForFilterObject(formState.time2)
        },
        {
          personId: formState.personId3,
          legNumber: 3,
          legEventId: RelayLegEventIdMap[formState.eventId].leg3EventId,
          legTime: formatTimeForFilterObject(formState.time3)
        },
        {
          personId: formState.personId4,
          legNumber: 4,
          legEventId: RelayLegEventIdMap[formState.eventId].leg4EventId,
          legTime: formatTimeForFilterObject(formState.time4)
        }]
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME_SELECT_TIME_TYPE);
  };

  useEffect(() => {
    if (meetTimeAddPostDataState.isSaved === true
      && state.tryPageRedirect === true) {
      const newArrayData = meetTimeAddPostDataState.arrayData;
      newArrayData.push(meetTimeAddPostDataState.objData);
      setMeetTimeAddPostDataState({
        ...meetTimeAddPostDataState,
        arrayData: newArrayData,
        isSaved: false
      });
      navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetTimeAddPostDataState]);

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const startDate = formatDate(meetState.objData.startDate);
      const endDate = formatDate(meetState.objData.endDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "Select a Swim Date" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (eventState.isArrayLoaded === false) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState.isArrayLoaded]);

  return {
    formState,
    errorState,
    eventState,
    meetTimeAddPostDataState,
    meetDatesState,
    sessionTypeState,
    eventCompetitionGenderTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    setFormData,
    meetState
  };

  function getInitialFormState() {
    return {
      personId1: Constants.DEFAULT_ID,
      pesonName1: '',
      personGender1: '',
      personId2: Constants.DEFAULT_ID,
      pesonName2: '',
      personGender2: '',
      personId3: Constants.DEFAULT_ID,
      pesonName3: '',
      personGender3: '',
      personId4: Constants.DEFAULT_ID,
      pesonName4: '',
      personGender4: '',
      eventId: Constants.DEFAULT_ID,
      meetId: meetState.objData.meetId,
      eventCompetitionGenderTypeId: Constants.DEFAULT_ID,
      sessionId: Constants.DEFAULT_ID,
      teamName: '',
      time: '',
      time1: '',
      time2: '',
      time3: '',
      time4: '',
      swimDate: '',
      swimDateId: Constants.DEFAULT_ID,
      finishPosition: '',
      eventName: '',
      memberId1: '',
      memberId2: '',
      memberId3: '',
      memberId4: '',
      lscId: '',
      clubCode: ''
    };
  }

  function getInitialState() {
    return {
      tryPageRedirect: false
    };
  };
}

export default useAddMeetTimeRelay;