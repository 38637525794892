import { useState } from 'react';

import TimeValidationData from './TimeValidationData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useTimeValidationData = () => {
  const [timeValidationState, setTimeValidationState] = useState(INITIAL_STATE);
  const [updateInvalidTimeState, setUpdateInvalidTimeState] = useState(INITIAL_STATE);

  const getTimeValidations = (meetId) => {
    return TimeValidationData.getTimeValidationsData(meetId, timeValidationState, setTimeValidationState);
  };

  const putTimeValidations = (meetId) => {
    return TimeValidationData.putTimeValidationsData(meetId, timeValidationState, setTimeValidationState);  
  }

  const putUpdateInvalidTime = (swimTimeRecognitionId, invalidTimeObj) => { 
    return TimeValidationData.putUpdateInvalidTimeData(swimTimeRecognitionId, invalidTimeObj, updateInvalidTimeState, setUpdateInvalidTimeState);
  }

  const deleteInvalidTime = (swimTimeRecognitionId, meetId) => {
    return TimeValidationData.deleteInvalidTimeData(swimTimeRecognitionId, meetId, timeValidationState, setTimeValidationState);  
  }

  return {
    timeValidationState,
    updateInvalidTimeState,
    getTimeValidations,
    putTimeValidations,
    putUpdateInvalidTime,
    deleteInvalidTime
  };
}

export default useTimeValidationData;