import { useContext, useState } from 'react';

import MeetTimesAddPostData from './MeetTimesAddPostData';
import MeetTimesData from './MeetTimesData';
import DeleteMeetTimesData from './deleteMeetTimes/DeleteMeetTimesData';

import { MeetTimesAddStateContext } from '../meetTimes/meetTimesAdd/MeetTimesAddContextProvider';
import { MeetTimesAddPostStateContext } from '../meetTimes/meetTimesAdd/MeetTimesAddPostContextProvider';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_DELETE_MEET_TIMES_STATE = {
  ...BASIC_INITIAL_STATE
}

const useMeetTimesData = () => {
  const [meetTimesAddState, setMeetTimesAddState] = useContext(MeetTimesAddStateContext);
  const [meetTimeAddPostDataState, setMeetTimeAddPostDataState] = useContext(MeetTimesAddPostStateContext);
  const [deleteMeetTimesState, setDeleteMeetTimesState] = useState(INITIAL_DELETE_MEET_TIMES_STATE);

  const getOrganization = (organizationId, treeState, setTreeState) => {
    MeetTimesData.getOrganization(organizationId, treeState, setTreeState);
  };

  const getOrgUnitAthletes = (orgUnitIds, treeState, setTreeState) => {
    MeetTimesData.getOrgUnitAthletes(orgUnitIds, treeState, setTreeState);
  };

  const getMeetTimes = (meetId, viewState, setViewState) => {
    MeetTimesData.getMeetTimes(meetId, viewState, setViewState);
  };

  const postMeetTime = (meetObj) => {
    MeetTimesAddPostData.postMeetTime(meetObj, meetTimeAddPostDataState, setMeetTimeAddPostDataState);
  };

  const deleteMeetTimes = (meetId) => {
    return DeleteMeetTimesData.deleteMeetTimesData(meetId, deleteMeetTimesState, setDeleteMeetTimesState);
  };

  const refreshMeetTimesAddState = (meetId) => {
    if (meetId !== meetTimesAddState.meetId) {
      setMeetTimesAddState({
        meetId,
        isArrayLoaded: false,
        arrayData: [],
        orgTreeViewIds: [],
        athleteTreeViewIds: [],
        message: ''
      });
    }
  };

  const updateOrgTreeViewIds = (orgTreeViewIds) => {
    setMeetTimesAddState({
      ...meetTimesAddState,
      orgTreeViewIds: orgTreeViewIds.map(orgUnit => orgUnit.id)
    });
  };

  const updateAthleteTreeViewIds = (athleteTreeViewIds) => {
    setMeetTimesAddState({
      ...meetTimesAddState,
      athleteTreeViewIds: athleteTreeViewIds.map(athlete => athlete.id),
      arrayData: athleteTreeViewIds,
      isArrayLoaded: true
    });
  };

  return {
    meetTimesAddState,
    meetTimeAddPostDataState,
    setMeetTimeAddPostDataState,
    deleteMeetTimesState,
    getOrganization,
    getOrgUnitAthletes,
    getMeetTimes,
    postMeetTime,
    deleteMeetTimes,
    refreshMeetTimesAddState,
    updateOrgTreeViewIds,
    updateAthleteTreeViewIds
  };
};

export default useMeetTimesData;