import { useState } from 'react';

import MeetResultDocFileData from './MeetResultDocFileData';

const useMeetResultDocFileData = () => {
  const [meetResultDocFileUploadState, setMeetResultDocFileUploadState] = useState(MeetResultDocFileData.INITIAL_MEET_RESULT_DOC_FILE_UPLOAD_STATE);

  const postMeetResultDocFile = (file) => {
    MeetResultDocFileData.postMeetResultDocFileData(file, meetResultDocFileUploadState, setMeetResultDocFileUploadState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (meetResultDocFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = meetResultDocFileUploadState.objData.uploadUrl.replace(MeetResultDocFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return meetResultDocFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(MeetResultDocFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    }
    catch (e) {
      return url;
    }
  };

  return {
    meetResultDocFileUploadState,
    postMeetResultDocFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useMeetResultDocFileData;