import React, { Fragment } from 'react';

import useAddMeetTimeRelay from './UseAddMeetTimeRelay';

import AddMeetTimeEventCombobox from '../../../../components/comboboxes/AddMeetTimeEventCombobox';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SearchMemberWithLscPopup from '../../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';
import LscDropdown from '../../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatTimeForInput } from '../../../../../common/utils/TimesUtils';
import Constants from '../../../../../common/utils/Constants';

const AddMeetTimeRelay = () => {
  const {
    formState,
    errorState,
    eventState,
    meetTimeAddPostDataState,
    meetDatesState,
    sessionTypeState,
    eventCompetitionGenderTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    setFormData,
    meetState
  } = useAddMeetTimeRelay();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Relay Meet Time Result</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SearchMemberWithLscPopup
              label={'Leg 1 Athlete*'}
              memberIdName={'memberId1'}
              orgUnitId={meetState.objData.hostOrgUnitId}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} 
              isForUsas={
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "USAS" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "LSC" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "CLUB"}
              />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SearchMemberWithLscPopup
              label={'Leg 2 Athlete*'}
              memberIdName={'memberId2'}
              orgUnitId={meetState.objData.hostOrgUnitId}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} 
              isForUsas={
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "USAS" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "LSC" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "CLUB"}
              />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SearchMemberWithLscPopup
              label={'Leg 3 Athlete*'}
              memberIdName={'memberId3'}
              orgUnitId={meetState.objData.hostOrgUnitId}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} 
              isForUsas={
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "USAS" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "LSC" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "CLUB"}
              />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SearchMemberWithLscPopup
              label={'Leg 4 Athlete*'}
              memberIdName={'memberId4'}
              orgUnitId={meetState.objData.hostOrgUnitId}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} 
              isForUsas={
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "USAS" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "LSC" ||
                meetState.objData.hostOrgUnit.orgLevel.levelCode === "CLUB"}
              />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Leg 1 Time"
              name="time1"
              error={errorState.time1}
              message={errorState.time1}
              value={formatTimeForInput(formState.time1)}
              onChange={(value, e) => { onFormValueChanged('time1', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Leg 2 Time"
              name="time2"
              error={errorState.time2}
              message={errorState.time2}
              value={formatTimeForInput(formState.time2)}
              onChange={(value, e) => { onFormValueChanged('time2', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Leg 3 Time"
              name="time3"
              error={errorState.time3}
              message={errorState.time3}
              value={formatTimeForInput(formState.time3)}
              onChange={(value, e) => { onFormValueChanged('time3', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Leg 4 Time"
              name="time4"
              error={errorState.time4}
              message={errorState.time4}
              value={formatTimeForInput(formState.time4)}
              onChange={(value, e) => { onFormValueChanged('time4', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Team Name"
              name="teamName"
              error={errorState.teamName}
              message={errorState.teamName}
              value={formState.teamName}
              onChange={(value) => { onFormValueChanged('teamName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <AddMeetTimeEventCombobox
              label="Event*"
              name="eventId"
              valueToMatch={formState.eventName}
              error={errorState.eventId}
              message={errorState.eventId}
              timesData={eventState.arrayData}
              isRelay={true}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(
                  newValue, 'eventId',
                  newValueLabel, 'eventName', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Dropdown
              label="Event Gender*"
              name="eventGenderId"
              options={eventCompetitionGenderTypeState}
              value={formState.eventCompetitionGenderTypeId}
              error={errorState.eventCompetitionGenderTypeId}
              message={errorState.eventCompetitionGenderTypeId}
              isLoading={false}
              onChange={(value) => { onFormValueChanged('eventCompetitionGenderTypeId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Place"
              name="finishPosition"
              type="number"
              min="1"
              error={errorState.finishPosition}
              message={errorState.finishPosition}
              value={formState.finishPosition}
              onChange={(value) => { onFormValueChanged('finishPosition', (value)) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Relay Team Time*"
              name="time"
              error={errorState.time}
              message={errorState.time}
              value={formatTimeForInput(formState.time)}
              onChange={(value, e) => { onFormValueChanged('time', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Dropdown
              label="Session"
              name="sessionId"
              options={sessionTypeState}
              value={formState.sessionId}
              error={errorState.sessionId}
              message={errorState.sessionId}
              isloading={false}
              onChange={(value) => { onFormValueChanged('sessionId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Dropdown
              label="Swim Date*"
              name="swimDateId"
              options={meetDatesState}
              value={formState.swimDateId}
              error={errorState.swimDateId}
              message={errorState.swimDateId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(
                  newValue, 'swimDateId',
                  newValueLabel, 'swimDate', e);
              }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <LscDropdown
              label="LSC*"
              name="lsc"
              value={formState.lscId}
              error={errorState.lscId}
              message={errorState.lscId}
              onChange={(value) => { onFormValueChanged('lscId', value) }} />&nbsp;
            
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <Input
              label="Club Code*"
              name="clubCode"
              value={formState.clubCode}
              error={errorState.clubCode}
              message={errorState.clubCode}
              onChange={(value) => { onFormValueChanged('clubCode', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetTimeAddPostDataState.isSaving} />
    </Fragment>
  )
}

export default AddMeetTimeRelay;