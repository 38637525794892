import { Fragment } from 'react';

import useMeetInfo from './UseMeetInfo';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetInfoRead = () => {
  const {
    state,
    formState,
    isLoading,
    Constants
  } = useMeetInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Info</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <Headings.H4>Primary</Headings.H4>
          <div className={global.HeaderText}>
            <div className="col-xs-12 col-md-6"><p><b>Meet Name:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.meetName || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Meet Abbreviation:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.meetAbbreviation || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Sanction Number:</b></p></div>
            <div className="col-xs-12 col-md-6"><p>{formState.meetSanctionNumber || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Meet Classification:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.meetClassificationName || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Meet Courses:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.meetCourse.length > 0 ?
              formState.meetCourse.map(x => `${x.name} `) : <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Meet Software:</b></p></div>
            <div className="col-xs-12 col-md-6"><p>{formState.meetSoftwareName || <span>No Info</span>}</p></div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <Headings.H4>Host</Headings.H4>
          <div className={global.HeaderText}>
            <div className="col-xs-12 col-md-6"><p><b>Organization Host:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{state.organizationName || <span>No Info</span>}</p></div>
            {state.lscName !== undefined &&
              <>
                <div className="col-xs-12 col-md-6"><p><b>LSC Host:</b></p></div>
                <div className="col-xs-12 col-md-6"> <p>{state.lscName || <span>No Info</span>}</p></div>
              </>}
            {state.hostOrgUnitName !== undefined &&
              <>
                <div className="col-xs-12 col-md-6"><p><b>{state.hostOrgUnitLabel}:</b></p></div>
                <div className="col-xs-12 col-md-6"> <p>{state.hostOrgUnitName || <span>No Info</span>}</p></div>
              </>}
          </div>
        </div>
        <div className='hidden-xs row'></div>
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <Headings.H4>Facility</Headings.H4>
          <div className={global.HeaderText}>
            <div className="col-xs-12 col-md-6"><p><b>Facility Name:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.facilityName || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>City:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{state.facilityCity || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>State:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{state.facilityState || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Country:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{state.facilityCountry || <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>Altitude (ft):</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{state.facilityAltitude !== '' ?
              state.facilityAltitude.toString() : <span>Unknown</span>}</p></div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <Headings.H4>Dates</Headings.H4>
          <div className={global.HeaderText}>
            <div className="col-xs-12 col-md-6"><p><b>Start Date:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : <span>No Info</span>}</p></div>
            <div className="col-xs-12 col-md-6"><p><b>End Date:</b></p></div>
            <div className="col-xs-12 col-md-6"> <p>{formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : <span>No Info</span>}</p></div>
          </div>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default MeetInfoRead;