import { useState } from 'react';

import SelectionsExportParametersData from './SelectionsExportParametersData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useSelectionsExportParametersData = () => {
  const [selectionsExportParametersState, setSelectionsExportParametersState] = useState(INITIAL_STATE);

  const getSelectionsExportParameters = (orgUnitId) => {
    return SelectionsExportParametersData.getSelectionsExportParametersData(
      orgUnitId, selectionsExportParametersState, setSelectionsExportParametersState);
  };

  return {
    selectionsExportParametersState,
    getSelectionsExportParameters
  };
};

export default useSelectionsExportParametersData;