import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getTimeValidationsData = (meetId, state, setState) => {
  const api = initApi(API_NAMES.TIMES, state, setState);

  const meetIdForUrl = encodeURIComponent(meetId);
  const url = `/Time/TimeValidation/${meetIdForUrl}`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const putTimeValidationsData = (meetId, state, setState) => {
  const api = initApi(API_NAMES.TIMES, state, setState);

  const meetIdForUrl = encodeURIComponent(meetId);
  const url = `/Time/TimeValidation/validate/${meetIdForUrl}`;

  return api?.executeArray ? api.executeArray(url, 'PUT') : null;
};

const putUpdateInvalidTimeData = (swimTimeRecognitionId, invalidTimeObj, state, setState) => {
  if (swimTimeRecognitionId > 0 && invalidTimeObj) {
    const api = initApi(API_NAMES.TIMES, state, setState);

    const swimTimeRecognitionIdForUrl = encodeURIComponent(swimTimeRecognitionId);
    const url = `/Time/TimeValidation/update/${swimTimeRecognitionIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'PUT', invalidTimeObj) : null;
  }
};

const deleteInvalidTimeData = (swimTimeRecognitionId, meetId, state, setState) => {
  if (swimTimeRecognitionId > 0 && meetId > 0) {
    const api = initApi(API_NAMES.TIMES, state, setState);

    const swimTimeRecognitionIdForUrl = encodeURIComponent(swimTimeRecognitionId);
    const meetIdForUrl = encodeURIComponent(meetId);
    const url = `/Time/TimeValidation/${swimTimeRecognitionIdForUrl}/${meetIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'DELETE') : null;
  }
};

const TimeValidationData = {
  getTimeValidationsData,
  putTimeValidationsData,
  putUpdateInvalidTimeData,
  deleteInvalidTimeData
};

export default TimeValidationData;