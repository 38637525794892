import { Fragment } from 'react';

import useMeetInfo from './UseMeetInfo';
import MeetInfoReadContent from './MeetInfoReadContent';

import OrgUnitTreeView from '../../../../common/components/tree/orgUnitTree/OrgUnitTreeView';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import MeetSoftwareDropdown from '../../../../common/components/dropdowns/meetSoftwareDropdown/MeetSoftwareDropdown';
import FacilityCombobox from '../../../../common/components/comboboxes/facilityCombobox/FacilityCombobox';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import MeetClassificationTreeView from '../../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView';

import global from '../../../../common/components/GlobalStyle.module.css';
import SessionsGrid from './SessionsGrid';

const MeetInfoWrite = () => {
  const {
    meetState,
    isLoading,
    isSaving,
    state,
    formState,
    errorState,
    courseOptionsState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onFacilityComboboxStateChange,
    onHostOrgUnitChanged,
    onDeleteMeet,
    Constants,
    onModalCanceled,
    onOpenModalClicked,
    modalState,
    canDelete,
    futureMeet,
    meetDateChangesModalState,
    onSaveMeet,
    onMeetDateChangesModalCanceled,
    meetDatesState,
    onUploadResultsDocClicked,
    meetResultDocFileUploadState,
    removeFileModalState,
    onRemoveResultsDocClicked,
    onRemoveFileModalCanceled,
    onOpenRemoveFileModalClicked
  } = useMeetInfo();

  //TODO: add to env var
  const MEET_CLASSIFICATION_ID_BLOCK_PARTY_OTS = 103;
  const MEET_CLASSIFICATION_ID_BLOCK_PARTY_NON_OTS = 104;

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        {(formState.meetClassificationId !== MEET_CLASSIFICATION_ID_BLOCK_PARTY_OTS && formState.meetClassificationId !== MEET_CLASSIFICATION_ID_BLOCK_PARTY_NON_OTS) ?
          <div className='row'>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <Headings.H6>Meet Results Document (Optional)</Headings.H6>
              <div className="usas-extra-top-margin">
              { formState.meetResultsDocUrl === '' || formState.meetResultsDocFileName.includes("Bad Request") ?
                <Fragment>
                  <label htmlFor="announcement-file-upload" className={global.FileUpload}>
                    Upload Results Document
                  </label>
                  <input hidden id="announcement-file-upload" type="file" onChange={onUploadResultsDocClicked} />
                  &nbsp;(*.pdf)
                </Fragment>
                :
                <Fragment>
                  <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded Document</SecondaryButton>&nbsp;
                  <p><b>Uploaded File Name:</b> {formState.meetResultsDocFileName}</p>
                </Fragment>
              }
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              {futureMeet === true ?
                <OrgUnitTreeView
                  label="Host Organization*"
                  name="hostOrgUnit"
                  value={formState.hostOrgUnit}
                  error={errorState.hostOrgUnit}
                  message={errorState.hostOrgUnit}
                  selectableLeavesOnly={false}
                  singleSelect={true}
                  onChange={(newValue) => { onHostOrgUnitChanged(newValue) }}
                  organizationId={undefined}
                /> :
                <ReadOnly
                  label="Host Organization"
                  name="hostOrgUnit"
                  value={formState.hostOrgUnit[0]?.name || ''}
                />}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="Meet Name*"
                name="meetName"
                value={formState.meetName}
                error={errorState.meetName}
                message={errorState.meetName}
                onChange={(value) => { onFormValueChanged('meetName', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="Meet Abbreviation"
                name="meetAbbreviation"
                value={formState.meetAbbreviation}
                error={errorState.meetAbbreviation}
                message={errorState.meetAbbreviation}
                onChange={(value) => { onFormValueChanged('meetAbbreviation', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="Sanction Number"
                name="meetSanctionNumber"
                value={formState.meetSanctionNumber}
                error={errorState.meetSanctionNumber}
                message={errorState.meetSanctionNumber}
                onChange={(value) => { onFormValueChanged('meetSanctionNumber', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <MultiSelect
                label="Meet Course(s)*"
                name="meetCourse"
                value={formState.meetCourse}
                error={errorState.meetCourse}
                message={errorState.meetCourse}
                options={courseOptionsState}
                isLoading={courseOptionsState.length === 0}
                onChange={(value) => { onFormValueChanged('meetCourse', value) }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <MeetSoftwareDropdown
                label="Meet Software"
                name="meetSoftwareId"
                value={formState.meetSoftwareId}
                error={errorState.meetSoftwareId}
                message={errorState.meetSoftwareId}
                onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetSoftwareId', newValueLabel, 'meetSoftwareName', e); }} />
            </div>
            <div className={formState.hostOrgUnit.length > 0 ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <FacilityCombobox
                  label="Facility*"
                  name="facilityId"
                  valueToMatch={formState.facilityName}
                  error={errorState.facilityId}
                  message={errorState.facilityId}
                  onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName', e); }}
                  orgUnitId={formState.hostOrgUnit[0]?.id || ''}
                  onFacilityComboboxStateChange={onFacilityComboboxStateChange} />
              </div>
            </div>
            <div className={formState.facilityId > 0 ? global.DisplayComponent : global.HideComponent}>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="City"
                  name="facilityCity"
                  value={state.facilityCity} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="State"
                  name="facilityState"
                  value={state.facilityState} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Country"
                  name="facilityCountry"
                  value={state.facilityCountry} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Altitude (ft)"
                  name="facilityAltitude"
                  value={state.facilityAltitude !== '' ? state.facilityAltitude.toString() : 'Unknown'} />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Fragment>
                <MeetClassificationTreeView
                  label="Meet Classification*"
                  name="meetClassification"
                  value={formState.meetClassification}
                  error={errorState.meetClassification}
                  message={errorState.meetClassification}
                  selectableLeavesOnly={true}
                  singleSelect={true}
                  onChange={(newValue) => { onFormValueChanged('meetClassification', newValue) }}
                  orgUnitId={formState.hostOrgUnit[0]?.id || undefined}
                  filterId={-1}
                  filterMode={"exclude_filter_id"}
                />
              </Fragment>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              {meetState.objData.meetResultsUploaded !== true ?
                <Fragment>
                  <DatePicker
                    label="Start Date*"
                    name="startDate"
                    value={formState.startDate}
                    error={errorState.startDate}
                    message={errorState.startDate}
                    onChange={(value) => {
                      onFormValueChanged('startDate', value);
                      onFormValueChanged('autogeneratedMeetYear', value.toString().substring(6, 10) !== '0000' ? value.toString().substring(6, 10) : '')
                    }}
                    countOfYears={154}
                    startYearOffset={-149} />
                </Fragment> :
                <ReadOnly
                  label="Start Date"
                  name="startDate"
                  value={formState.startDate} />
              }
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              {meetState.objData.meetResultsUploaded !== true ?
                <Fragment>
                  <DatePicker label="End Date*"
                    name="endDate"
                    value={formState.endDate}
                    error={errorState.endDate}
                    message={errorState.endDate}
                    onChange={(value) => { onFormValueChanged('endDate', value); }}
                    countOfYears={154}
                    startYearOffset={-149} />
                </Fragment> :
                <ReadOnly
                  label="End Date"
                  name="endDate"
                  value={formState.endDate} />
              }
            </div>
          </div>
          : <MeetInfoReadContent formState={formState} state={state} />
        }
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          {(meetState.objData.meetClassificationId !== 103 && meetState.objData.meetClassificationId !== 104) &&
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>&nbsp;
              {canDelete === true ?
                <SecondaryButton type="button" onClick={onOpenModalClicked}>Delete</SecondaryButton>
                : <Fragment />}
            </div>
          }
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetResultDocFileUploadState.isObjLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Meet:</b> {modalState.meetInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteMeet}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={meetDateChangesModalState.modalTitle}
        displayPopUp={meetDateChangesModalState.displayPopUp}
        onModalCanceled={onMeetDateChangesModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Meet:</b> {meetDateChangesModalState.meetInfo}</p>
            <p><b>Meet Sessions:</b> Please select a new session date for each session</p>
            <SessionsGrid
              showOptionalFields={false}
              showOtsField={false}
              sortedMeetSessionArray={meetState.objData.meetSession}
              meetDatesState={meetDatesState}
              formState={formState}
              errorState={errorState}
              onValueTextPairChanged={onValueTextPairChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <p><b>IF THIS MEET USES ONLINE MEET ENTRY (OME), AFTER SAVING PLEASE REVISIT THE MEET HOST SETUP TO ENSURE THE VARIOUS OME MEET DATES MAKE SENSE WITH THE NEW MEET DATES.</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveMeet}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onMeetDateChangesModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Meet Results Document</p>
            <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveResultsDocClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  )
}

export default MeetInfoWrite;