
import { Fragment } from 'react';
import useSelectionsExport from './UseSelectionsExport';
import { EXPORT_NAME } from '../components/leftNav/SelectionsLeftNavConstants';
import SelectionsLeftNav from '../components/leftNav/SelectionsLeftNav';
import ExportForm from '../components/forms/ExportForm';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const SelectionsExport = () => {
  const {
    selectionsState,
    selectionsEntriesExportState,
    selectionsTimeStandardState,
    selectionsExportParametersState,
    onExportSubmitFormCallback } = useSelectionsExport();

  return (
    <Fragment>
      <SelectionsLeftNav viewName={EXPORT_NAME} viewTitle={'Export'}>
        <div className='row'>
          <div className="col-xs-12 col-sm-6">
            {/* use selectionsTimeStandardState to display standardDescription here */}
            <b>Time Standard:</b>&nbsp; 
            {selectionsExportParametersState.isArrayLoaded === true && 
             selectionsTimeStandardState.isArrayLoaded &&
             selectionsExportParametersState.arrayData[2].ParameterValue > 0 &&
             selectionsTimeStandardState.arrayData.find(x => x.timeStandardId === parseInt(selectionsExportParametersState.arrayData[2].ParameterValue)).standardDescription || "N/A"
            }
          </div>
        </div>
        <ExportForm
          onSubmitFormCallback={onExportSubmitFormCallback}
          selectionsStateObj={selectionsState.objData}
          submitButtonText={'Run Export'}
          secondaryButtonText={'Cancel'}>
        </ExportForm>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={selectionsEntriesExportState.isObjLoading || selectionsExportParametersState.isArrayLoading} />
      </SelectionsLeftNav>
    </Fragment>
  );
}

export default SelectionsExport;