import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const TimesValidationSmallGrid = ({ gridData, isLoading, onEditTime, onDeleteTime }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ?
        gridData.map((invalidTime) =>
        (<div className={global.SmallTableRow} key={invalidTime.swimTimeRecognitionId}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{invalidTime.name}</span>&nbsp;
            {onEditTime &&
              <button className={global.IconButton}
                type="button"
                onClick={(e) => onEditTime(e, invalidTime)}>
                <EditIcon />
              </button>}
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onDeleteTime(e, invalidTime.swimTimeRecognitionId)}>
              <DeleteIcon toolTipText="Delete all time usage" />
            </button>
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{invalidTime.name}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member ID</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{invalidTime.memberId}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Event</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{invalidTime.eventCode}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Swim Time</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{invalidTime.swimTime}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>First Name (file)</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{invalidTime.firstNameFromFile}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Last Name (file)</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{invalidTime.lastNameFromFile}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Birth Date (file)</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>
                {formatDate(invalidTime.birthDateFromFile) === Constants.DEFAULT_MIN_DATE ? 'N/A' : formatDate(invalidTime.birthDateFromFile)}
              </div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>LSC/Club (file)</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{invalidTime.lscCodeFromFile}/{invalidTime.clubCodeFromFile}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>LSC/Club (assigned)</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{invalidTime.assignedLscCode}/{invalidTime.assignedClubCode}</div>
            </div>
          </div>
        </div>)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Invalid Times</div>
            </div>
          </div>
        )
    }
  </div >
);

export default TimesValidationSmallGrid;