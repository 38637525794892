import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MeetInfoValidation';

import NavLinks from '../NavLinks';

import useMeetResultDocFileData from '../../../state/meetResultDocFile/UseMeetResultDocFileData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrganizationData from '../../../../common/state/organization/UseOrganizationData';
import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetSoftwareData from '../../../../common/state/meet/meetSoftware/UseMeetSoftwareData';
import useCourseData from '../../../../common/state/course/UseCourseData';
import useOrgUnitData from '../../../../common/state/orgUnit/UseOrgUnitData';
import useMeetStatusData from '../../../../common/state/meet/meetStatus/UseMeetStatusData';
import useMeetCompetitionSummaryData from '../../../state/meetCompetitionSummary/UseMeeetCompetitionSummaryData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';

const DEFAULT_ORG_UNIT_LABEL = 'Secondary Organization Host';
const USAS_ORG_UNIT_LABEL = 'Club Host';
const FINA_ORG_UNIT_LABEL = 'Country Host';
const NCAA_ORG_UNIT_LABEL = 'School Host';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';

const useMeetInfo = () => {
  const navigate = useNavigate();
  const { meetResultDocFileUploadState, postMeetResultDocFile, getFileNameFromSavedUploadUrl, getFileNameFromUrl } = useMeetResultDocFileData();
  const [state, setState] = useState(getInitialState);
  const [fileErrorState, setFileErrorState] = useState({ error: '' });
  const [meetDatesState, setMeetDatesState] = useState([{ id: Constants.DEFAULT_ID, name: "--" }]);
  const [modalState, setModalState] = useState(getInitialModalState());
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const [meetDateChangesModalState, setMeetDateChangesModalState] = useState(getInitialMeetDateChangesModalState());
  const [courseOptionsState, setCourseOptionsState] = useState([]);
  const { meetState, putMeet, deleteMeet } = useMeetData();
  const { meetStatusState, getMeetStatus } = useMeetStatusData();
  const { meetCompetitionSummaryState, getMeetCompetitionSummary } = useMeetCompetitionSummaryData();
  const { courseState, getCourses } = useCourseData();
  const { organizationState } = useOrganizationData();
  const { orgUnitState } = useOrgUnitData();
  const { meetSoftwareState } = useMeetSoftwareData();
  const { environmentVariableState, USAS_ORGANIZATION_ID, FINA_ORGANIZATION_ID, NCAA_ORGANIZATION_ID, LSC_ORG_LEVEL_ID } = useEnvironmentVariableData();
  const { errorState, setErrors, formState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged, resetForm, setFormData, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const onDeleteMeet = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteMeet(meetState.objData.meetId);
    setModalState({
      ...getInitialModalState()
    });
    setState({ ...state, tryRedirect: true });
  }

  const onFacilityComboboxStateChange = (facilityComboboxState) => {
    if (facilityComboboxState.data.length === 0) {
      setFormState({
        ...formState,
        facilityId: null,
        facilityName: ''
      });
    } else {
      const originalFacility = facilityComboboxState.data.find(x => meetState.objData?.facilityId === x.facilityId);

      setFormState({
        ...formState,
        facilityId: originalFacility?.facilityId || Constants.DEFAULT_ID,
        facilityName: originalFacility?.facilityName ? `${originalFacility?.facilityName} (${originalFacility?.city}, ${originalFacility?.stateCode})` : ''
      });
    }

    setState({
      ...state,
      facilityData: facilityComboboxState.data
    });
  };

  const onHostOrgUnitChanged = (newValue) => {
    setFormState({
      ...formState,
      hostOrgUnit: newValue,
      facilityId: Constants.DEFAULT_ID,
      facilityName: ''
    });

    setIsDirty(true);
  };

  const onOpenModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const meetInfo = formState.meetName + ' (Host: ' + (formState.hostOrgUnit[0]?.name || '') + ', Meet Dates: ' + formState.startDate + ' - ' + formState.endDate + ')';
    setModalState({
      ...modalState,
      meetId: formState.meetId,
      meetInfo: meetInfo,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onOpenMeetDateChangesModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const meetInfo = formState.meetName + ' (Host: ' + (formState.hostOrgUnit[0]?.name || '') + ', Meet Dates: ' + formState.startDate + ' - ' + formState.endDate + ')';
    setMeetDateChangesModalState({
      ...meetDateChangesModalState,
      meetId: formState.meetId,
      meetInfo: meetInfo,
      displayPopUp: true
    });
  };

  const onMeetDateChangesModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setMeetDateChangesModalState(getInitialMeetDateChangesModalState());
    setIsDirty(true);
  };

  const onSaveMeetSimple = () => {
    if (formState.meetName.length > 0) {
      putMeet(meetState.objData.meetId, createPUTObject());
    }
  }

  const onSaveMeet = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let errors = {};
    for (let i = 0; i < meetState.objData?.meetSession?.length; i++) {
      let objProp1 = `sessionDateId${meetState.objData?.meetSession[i].meetSessionId}`;
      if (formState[objProp1] === Constants.DEFAULT_ID) {
        errors[objProp1] = 'Required';
      }
    }
    if (Object.keys(errors).length === 0) {
      putMeet(meetState.objData.meetId, createPUTObject());
    }
    else {
      setErrors({ ...errors });
    }
  }

  const onUploadResultsDocClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (meetResultDocFileUploadState.isObjLoading === true) {
      setFileErrorState({ ...fileErrorState, error: SAVING_ERROR_MSG });
    }
    else {
      setFileErrorState({ ...fileErrorState, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF) {
          postMeetResultDocFile(file);
          setFileErrorState({ ...fileErrorState, error: '' });
        }
        else {
          setFileErrorState({ ...fileErrorState, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveResultsDocClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });

    if (meetResultDocFileUploadState.isObjLoading === true) {
      setFileErrorState({ ...fileErrorState, error: SAVING_ERROR_MSG });
    }
    else {
      setFileErrorState({ ...fileErrorState, error: '' });
      setFormState({ ...formState, meetResultsDocUrl: '', meetResultsDocFileName: '' });
    }
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: formState.meetResultsDocFileName, displayPopUp: true });
  }

  const getMeetDates = (startDate, endDate) => {
    let dates = []
    const theStartDate = new Date(startDate)
    const theEndDate = new Date(endDate)
    while (theStartDate <= theEndDate) {
      dates = [...dates, formatDate(theStartDate)]
      let newStartDate = theStartDate.getDate() + 1;
      theStartDate.setDate(newStartDate)
    }
    return dates
  };

  function makeFormStateUpdateObj(meetSessionArray, mode) {
    if (meetSessionArray === undefined) return {};

    let formStateObj = {};
    if (mode === 'INITIALIZE') {
      formStateObj = {
        meetId: Constants.DEFAULT_ID,
        hostOrgUnit: [],
        meetName: '',
        meetAbbreviation: '',
        meetSanctionNumber: '',
        meetCourse: [],
        meetSoftwareId: Constants.DEFAULT_ID,
        meetSoftwareName: '',
        meetClassification: [],
        meetClassificationName: '',
        facilityId: Constants.DEFAULT_ID,
        facilityName: '',
        startDate: Constants.BLANK_DATE_STRING,
        endDate: Constants.BLANK_DATE_STRING,
        meetResultsDocUrl: '',
        meetResultsDocFileName: ''
      }
    }
    else if (mode === 'POPULATE') {
      formStateObj = {
        ...formState
      }
    }

    for (let i = 0; i < meetSessionArray.length; i++) {
      let sessionDateId = Constants.DEFAULT_ID;
      let sessionDate = "";
      let objProp1 = `sessionDateId${meetSessionArray[i].meetSessionId}`;
      let objProp2 = `sessionDate${meetSessionArray[i].meetSessionId}`;
      sessionDate = (meetSessionArray.find(x => x.meetSessionId === (meetSessionArray[i].meetSessionId))?.sessionDate || '');
      if (sessionDate) {
        sessionDateId = (meetDatesState?.find(x => x.name === formatDate(sessionDate))?.id || '');
      }
      formStateObj[objProp1] = sessionDate && sessionDateId ? sessionDateId : Constants.DEFAULT_ID;
      formStateObj[objProp2] = sessionDate && sessionDateId ? formatDate(sessionDate) : '';
    }

    return formStateObj;
  }

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const meetDatesRange = getMeetDates(formState.startDate, formState.endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "--" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, formState.startDate, formState.endDate]);

  useEffect(() => {
    if (meetDatesState.length > 1) {
      const formStateObj = makeFormStateUpdateObj(meetState.objData?.meetSession || [], 'POPULATE');
      setFormState({ ...formState, ...formStateObj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetDatesState, meetDateChangesModalState]);

  useEffect(() => {
    if (meetState.isSaved === true && meetState.objData?.meetId > 0) {
      getMeetCompetitionSummary(meetState.objData.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isSaved, meetState.objData.startDate, meetState.objData.endDate, meetState.objData.meetName]);

  useEffect(() => {
    if (meetStatusState.isObjLoaded !== true && meetState.objData?.meetId > 0) {
      getMeetStatus(meetState.objData.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetStatusState, meetState.objData.meetId]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true &&
      courseState.isLoading !== true && courseState.isLoaded !== true) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, courseState]);

  useEffect(() => {
    if (courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setCourseOptionsState(courseOptions);
    }
  }, [courseState]);

  //TODO: The following should be handled in onFacilityComboboxStateChange.  This useEffect causes 
  // unecessary state-render cycles (aka - hinders page performance)
  useEffect(() => {
    let selectedFacility;

    if (formState.facilityId > 0) {
      if (formState.facilityId === meetState.objData?.facilityId) {
        selectedFacility = meetState.objData.facility;
      } else {
        selectedFacility = state.facilityData.find(x => x.facilityId === formState.facilityId);
      }
    }
    setState({
      ...state,
      facilityCity: selectedFacility?.city || '',
      facilityState: selectedFacility?.stateCode || '',
      facilityCountry: selectedFacility?.country || '',
      facilityAltitude: selectedFacility?.altitude !== null && selectedFacility?.altitude >= 0 ? selectedFacility?.altitude : ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.facilityId]);

  // TODO:  potential to save on excess page state-render cycles here.  Is it really necessary to update
  // 2 different state objects in this useEffect?
  useEffect(() => {
    if (meetState.isObjLoaded === true && meetState.isSaving === false && meetSoftwareState.isLoaded === true
      && courseOptionsState.length > 0 && organizationState.isLoaded === true) {
      const courseIdNamePairs = HierarchicalDataUtils.GetNameIdPairs(courseOptionsState, meetState.objData?.meetCourse?.map(course => course.courseId));
      
      setFormData({
        ...formState,
        meetId: meetState.objData.meetId || Constants.DEFAULT_ID,
        hostOrgUnit: [{ id: meetState.objData.hostOrgUnit.orgUnitId, name: meetState.objData.hostOrgUnit.orgUnitName }],
        meetName: meetState.objData.meetName || '',
        meetAbbreviation: meetState.objData.meetAbbreviation || '',
        meetCourse: courseIdNamePairs,
        meetClassificationId: meetState.objData.meetClassificationId,
        meetClassificationName: meetState.objData.meetClassification.typeName || '',
        meetClassification: [{ id: meetState.objData.meetClassification.meetClassificationId, name: meetState.objData.meetClassification.typeName }],
        facilityId: meetState.objData.facilityId,
        facilityName: meetState.objData.facility?.facilityName ? `${meetState.objData.facility?.facilityName} (${meetState.objData.facility?.city}, ${meetState.objData.facility?.stateCode})` : '',
        startDate: formatDate(meetState.objData.startDate),
        endDate: formatDate(meetState.objData.endDate),
        meetSoftwareId: meetState.objData.meetSoftwareId || Constants.DEFAULT_ID,
        meetSoftwareName: meetState.objData.meetSoftwareId > 0 ? (meetSoftwareState.dataAsOptionsWithNames.find(x => x.id === meetState.objData.meetSoftwareId)?.name || '') : '',
        meetSanctionNumber: meetState.objData.meetSanctionNumber || '',
        meetResultsDocUrl: meetState.objData.meetResultsDocUrl || '',
        meetResultsDocFileName: meetState.objData.meetResultsDocUrl ? getFileNameFromUrl(meetState.objData.meetResultsDocUrl) : ''
      });

      const organizationId = meetState.objData.hostOrgUnit.organizationId;
      let isHostOrgLSC = undefined;

      if (organizationId === USAS_ORGANIZATION_ID) {
        isHostOrgLSC = isHostOrgUnitLSC(meetState.objData.hostOrgUnit);
      }

      setState({
        ...state,
        facilityCity: meetState.objData.facility?.city || '',
        facilityState: meetState.objData.facility?.stateCode || '',
        facilityCountry: meetState.objData.facility?.country || '',
        facilityAltitude: meetState.objData.facility?.altitude || '',
        organizationName: getHostOrganizationName(organizationId) || '',
        lscName: isHostOrgLSC !== undefined ? isHostOrgLSC === true ?
          meetState.objData.hostOrgUnit.orgUnitName : meetState.objData.hostOrgUnit.parentOrgUnitName
          : undefined,
        hostOrgUnitLabel: getHostOrgUnitLabel(organizationId),
        hostOrgUnitName: isHostOrgLSC !== true ? meetState.objData.hostOrgUnit.orgUnitName : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, meetSoftwareState, organizationState, courseOptionsState]);

  useEffect(() => {
    if (meetState.isSaving === false && state.tryRedirect === true) {
      navigate(NavLinks.MEET_SEARCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, state.tryRedirect]);

  useEffect(() => {
    if (meetResultDocFileUploadState.isObjLoaded === true && Object.keys(meetResultDocFileUploadState.objData).length > 0) {
      setIsDirty(true);
      setFormState({ ...formState, meetResultsDocUrl: meetResultDocFileUploadState.objData.uploadUrl, meetResultsDocFileName: getFileNameFromSavedUploadUrl() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetResultDocFileUploadState]);

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      onSaveMeetSimple();
    }
  }, [formState.meetResultsDocUrl]);

  function submitFormCallback() {
    const needToHandleMeetDateChanges = checkForChangesToMeetDates();
    if (needToHandleMeetDateChanges === false) {
      if (meetState.isSaving !== true) {
        putMeet(meetState.objData.meetId, createPUTObject());
      }
    }
    else {
      onOpenMeetDateChangesModalClicked();
    }
  }

  function checkForChangesToMeetDates() {
    if (((formState.startDate !== formatDate(meetState.objData.startDate)) ||
      (formState.endDate !== formatDate(meetState.objData.endDate))) &&
      meetState.objData?.meetSession?.length > 0) {
      return true;
    }
    return false;
  }

  function getHostOrgUnitLabel(organizationId) {
    switch (organizationId) {
      case USAS_ORGANIZATION_ID:
        return USAS_ORG_UNIT_LABEL;
      case FINA_ORGANIZATION_ID:
        return FINA_ORG_UNIT_LABEL;
      case NCAA_ORGANIZATION_ID:
        return NCAA_ORG_UNIT_LABEL;
      default:
        return DEFAULT_ORG_UNIT_LABEL;
    }
  }

  function getHostOrganizationName(organizationId) {
    return organizationState.dataAsOptionsWithNames.find(x => x.id === organizationId)?.name;
  }

  function isHostOrgUnitLSC(hostOrgUnit) {
    if (hostOrgUnit.orgLevelId === LSC_ORG_LEVEL_ID) {
      return true;
    }
    return false;
  }

  function createPUTObject() {
    const copyMeet = JSON.parse(JSON.stringify(meetState.objData));
    let meetSessionModified = []
    if (meetDateChangesModalState.displayPopUp === true) {
      meetSessionModified = copyMeet.meetSession.map((ms) => {
        let name = 'sessionDate' + ms.meetSessionId;
        let sessionDate = formState[name]
        return { ...ms, sessionDate: sessionDate }
      })
    }
    if (meetDateChangesModalState.displayPopUp === true) {
      setMeetDateChangesModalState(getInitialMeetDateChangesModalState);
    }
    return {
      ...copyMeet,
      meetName: formState.meetName.trim(),
      hostOrgUnitId: formState.hostOrgUnit[0].id,
      meetClassificationId: formState.meetClassification[0].id,
      hostOrgUnit: undefined,
      meetSoftwareId: formState.meetSoftwareId > 0 ? formState.meetSoftwareId : null,
      meetSoftware: undefined,
      facilityId: formState.facilityId > 0 ? formState.facilityId : null,
      facility: undefined,
      meetAbbreviation: formState.meetAbbreviation.trim(),
      meetCourse: formState.meetCourse.map(course => { return { courseId: course.id, meetId: copyMeet.meetId } }),
      meetSanctionNumber: formState.meetSanctionNumber.trim(),
      startDate: formState.startDate,
      endDate: formState.endDate,
      meetSession: meetSessionModified.length > 0 ? meetSessionModified : copyMeet.meetSession,
      meetResultsDocUrl: formState.meetResultsDocUrl
    };
  }

  function getInitialState() {
    return {
      facilityData: [],
      facilityCity: '',
      facilityState: '',
      facilityCountry: '',
      facilityAltitude: '',
      organizationName: '',
      lscName: undefined,
      hostOrgUnitLabel: DEFAULT_ORG_UNIT_LABEL,
      hostOrgUnitName: undefined,
      tryRedirect: false
    };
  }

  function getInitialFormState() {
    return makeFormStateUpdateObj(meetState.objData?.meetSession || [], 'INITIALIZE');
  }

  function getInitialModalState() {
    return {
      displayPopUp: false,
      meetId: '',
      meetInfo: '',
      modalTitle: 'Delete Meet?'
    };
  }

  function getInitialMeetDateChangesModalState() {
    return {
      displayPopUp: false,
      meetId: '',
      meetInfo: '',
      modalTitle: 'Handle Changes to Meet Dates - Update Session Dates'
    };
  }

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    }
  }

  return {
    meetState,
    modalState,
    isLoading: orgUnitState.isArrayLoading || meetSoftwareState.isLoading || organizationState.isLoading
      || courseOptionsState.length === 0 || meetCompetitionSummaryState.isObjLoading,
    isSaving: meetState.isSaving,
    state,
    formState,
    errorState,
    courseOptionsState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onFacilityComboboxStateChange,
    onHostOrgUnitChanged,
    onDeleteMeet,
    organizationState,
    Constants,
    onOpenModalClicked,
    onModalCanceled,
    canDelete: meetStatusState.isObjLoaded === true && meetStatusState.objData?.hasSwimTimes !== true,
    futureMeet: new Date(meetState.objData.startDate) > new Date(),
    meetDateChangesModalState,
    onSaveMeet,
    onMeetDateChangesModalCanceled,
    meetDatesState,
    onUploadResultsDocClicked,
    meetResultDocFileUploadState,
    removeFileModalState,
    onRemoveResultsDocClicked,
    onRemoveFileModalCanceled,
    onOpenRemoveFileModalClicked
  };
}

export default useMeetInfo;