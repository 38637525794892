import { Fragment } from 'react';

import useRaceStatsUpload from './UseRaceStatsUpload';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MessageBox = ({ resultLabel, result, stat1Label, stat1, stat2Label, stat2, procTimeLabel, processingTime }) => {
  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-6" style={{ backgroundColor: 'lightblue', marginLeft: 10, marginBottom: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 7 }}>
        <div style={{ fontSize: 18, color: 'slategray' }}><b>{resultLabel}</b> {result}</div>
        <div style={{ fontSize: 18, color: 'slategray' }}><b>{stat1Label}</b> {stat1}</div>
        <div style={{ fontSize: 18, color: 'slategray' }}><b>{stat2Label}</b> {stat2}</div>
        <div style={{ fontSize: 18, color: 'slategray' }}><b>{procTimeLabel}</b> {processingTime.slice(0, -5)}</div>
      </div>
    </div>
  );
}

const INVALID_FILE_MESSAGE = "This is not a valid Race Stats file";

const RaceStatsUpload = () => {
  const {
    jsonFileState,
    meetRaceStatsState,
    meetRaceStatsVideosState,
    FileUpload,
    onSubmitRaceStatsRaces,
    onGetRaceStatsVideos,
    //onSaveVideoLinks
  } = useRaceStatsUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Race Stats Upload</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <FileUpload />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin"
        style={{ display: (jsonFileState.raceStatsContent.length === 0) ? 'none' : 'flex' }}>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSubmitRaceStatsRaces}>Upload Race Stat Races</PrimaryButton>
        </div>
      </div>
      {meetRaceStatsState.isObjLoaded === true && meetRaceStatsState.isSaved === true
        ? 
        <MessageBox
          resultLabel="Status:"
          result={meetRaceStatsState.objData.result}
          stat1Label="Individual Races Uploaded:"
          stat1={meetRaceStatsState.objData.indRacesUploaded}
          stat2Label="Relay Races Uploaded:"
          stat2={meetRaceStatsState.objData.relayRacesUploaded}
          procTimeLabel="Processing Time:"
          processingTime={meetRaceStatsState.objData.sqlProcessingTime} />
        : <Fragment />}
      {jsonFileState.isValidFileType === false
        ? <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p className={global.Error} style={{ fontSize: 25 }}>{jsonFileState.isValidFileType === false && INVALID_FILE_MESSAGE}</p>
          </div>
        </div>
        : <Fragment />}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-6">
          <ActionIntraPageButton type="button" onClick={onGetRaceStatsVideos}>Get Videos</ActionIntraPageButton>&nbsp;&nbsp;
          {meetRaceStatsVideosState.isObjLoaded &&
            <>
              {/* {meetRaceStatsVideosState.objData.data?.length > 0 &&
                <>
                  <SecondaryButton type="button" onClick={onSaveVideoLinks}>Save Video Links</SecondaryButton>
                  <br />
                </>
              } */}
              <p>{meetRaceStatsVideosState.objData.total} Videos were found for this meet</p>
              {meetRaceStatsVideosState.isSaved ?
                <MessageBox
                  resultLabel="Status:"
                  result={meetRaceStatsVideosState.objData.result}
                  stat1Label="Race Stats videos matched:"
                  stat1={meetRaceStatsVideosState.objData.raceStatsVideosMatched}
                  stat2Label="Swim Time videos matched:"
                  stat2={meetRaceStatsVideosState.objData.swimTimeVideosMatched}
                  procTimeLabel="Processing Time:"
                  processingTime={meetRaceStatsVideosState.objData.sqlProcessingTime} />
                : <Fragment />
              }
            </>
          }
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        // eslint-disable-next-line react/no-children-prop
        children={<p>Please be patient. Uploading large amounts of data.</p>}
        displayPopUp={meetRaceStatsState?.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        // eslint-disable-next-line react/no-children-prop
        children={<p>Retrieving videos...</p>}
        displayPopUp={meetRaceStatsVideosState?.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        // eslint-disable-next-line react/no-children-prop
        children={<p>Saving videos...</p>}
        displayPopUp={meetRaceStatsVideosState?.isSaving} />
    </Fragment>
  );
};

export default RaceStatsUpload;