import { Fragment } from 'react';
import useTimesValidationDetail from './UseTimesValidationDetail';
import Headings from '../../../../common/components/headings/Headings';
import SearchMemberWithLscPopup from '../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import { formatTimeForInput } from '../../../../common/utils/TimesUtils';
import Input from '../../../../common/components/inputs/Input';

const TimesValidationDetail = () => {
  const {
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onBackButtonClicked,
    onValueTextPairChanged,
    genderOptions 
  } = useTimesValidationDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times Validation Edit</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        {formState.isRelay === false &&
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        }
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Time*"
              name="time"
              error={errorState.time}
              message={errorState.time}
              value={formatTimeForInput(formState.time)}
              onChange={(value) => { onFormValueChanged('time', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="LSC Code"
              name="lscCode"
              error={errorState.lscCode}
              message={errorState.lscCode}
              value={formState.lscCode}
              onChange={(value) => { onFormValueChanged('lscCode', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Club Code"
              name="clubCode"
              error={errorState.clubCode}
              message={errorState.clubCode}
              value={formState.clubCode}
              onChange={(value) => { onFormValueChanged('clubCode', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Event Gender"
            name="eventCompetitionGenderTypeId"
            options={genderOptions}
            value={formState.eventCompetitionGenderTypeId}
            error={errorState.eventCompetitionGenderTypeId}
            message={errorState.eventCompetitionGenderTypeId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'eventCompetitionGenderTypeId', newValueLabel, 'eventCompetitionGenderTypeName');
            }} />
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState?.error}</p>}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
    </Fragment>);
}

export default TimesValidationDetail;

